import React from 'react'
import { grid, theme } from '../../styles/theme'
import Button from '../Button'
import RichContent from '../RichContent'
import Section from '../Section'
import ImageWithHotspots from '../ImageWithHotspots'
import { createUseStyles } from '../../helpers/createStyles'
import cn from 'classnames'
import useScrollTrigger from '../../hooks/useScrollTrigger'

export default function CollectionPreview ({ data }) {
  const styles = useStyles()
  const { title, copy, image, link, columnCount } = data

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll(`h2, .${styles.copy}, .${styles.link}, .${styles.image}`), { y: 40, opacity: 0, stagger: 0.1, ...defaults })
    }
  )

  return (
    <Section grid ref={sectionRef}>
      <h2 className={styles.title}>{title}</h2>
      <RichContent className={cn(styles.copy, columnCount === 'double' && styles.doubleColumn)} content={copy} />
      <Button size='lg' className={styles.link} link={link} />
      <ImageWithHotspots className={styles.image} image={image.image} hotspots={image.hotspots} />
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    gridRow: 1,
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridRow: 1,
      gridColumn: '1 / span 4'
    }
  },
  copy: {
    gridRow: 2,
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridRow: 1,
      gridColumn: '5 / span 3'
    }
  },
  doubleColumn: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 6',
      columnCount: 2,
      columnGap: `${grid.md.gutter}px`
    }
  },
  link: {
    gridRow: 3,
    gridColumn: '1 / -1',
    marginTop: theme.spacingPx(1),
    [theme.breakpoints.up('md')]: {
      gridRow: 2,
      gridColumn: '5 / span 3'
    }
  },
  image: {
    gridRow: 4,
    gridColumn: '1 / -1',
    marginTop: theme.spacingPx(7),
    zIndex: 4,
    [theme.breakpoints.up('md')]: {
      gridRow: 3,
      gridColumn: '5 / span 8'
    }
  }
})
